.pList{
  width: 100%;
  max-width: 1150px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.pListItem{
  flex:1;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem !important;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  /* padding: 5px; */
  background-color: #ffb606;
  overflow: hidden;
  text-align: center;
  z-index: 1 !important;
}

.pListImg{
  width: 100%;
  height: 12rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}
/* .pListImg:hover {
  scale: 120%;
} */

.pListTitles {
  padding: 0.5rem 0;
  z-index: 10 !important;
}
.pListTitles>h1{
  font-size: 18px;
  color: #444;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
}
.pListTitles>h2{
  font-size: 14px;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  line-height: 1;
  padding-bottom: 0.5rem;
}