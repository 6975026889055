.listContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.listWrapper {
  width: 100%;
  max-width: 1024px;
  display: flex;
  gap: 20px;
}

.listSearch {
  flex: 1;
  border: 1px solid rgb(191, 191, 191);
  padding: 20px;
  border-radius: 5px;
  position: sticky;
  top: 100px;
  height: max-content;
}

.lsTitle{
  font-size: 20px;
  color: #555;
  margin-bottom: 10px;
}

.lsItem{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.lsItem>label{
  font-size: 12px;
  
}

.lsItem>input{
  height: 30px;
  /* border: none; */
  /* border: 1px solid rgb(191, 191, 191); */

  padding: 5px;
}
.lsItem>span{
  height: 30px;
  padding: 5px;
  border: 1px solid rgb(107, 107, 107);
  color: rgb(107, 107, 107);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lsOptions{
  padding: 10px;
}

.lsOptionItem{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #555;
  font-size: 12px;
}

.lsOptionInput{
  width: 50px;
}

.listSearch>button{
  padding: 10px;
  background-color: #febb02;
  border: 2px solid #febb02;
  /* background-color: #0071c2; */
  color: #333;
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.1s;
}
.listSearch>button:hover {
background-color: transparent;
color: #febb02;
}

.listResult {
  flex: 3;
}
