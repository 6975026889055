.fp {
  width: 100%;
  max-width: 1150px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 5rem;
}

.fpItem{
  flex: 1;
  /* gap: 10px; */
  display: flex;
  flex-direction: column;
}

.fpItem .fpContent {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  position: relative;
  z-index: 1;
  border-right: 1px solid #c0c0c0;
  border-left: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  padding: 0.5rem 1rem;
}

.fpBackground {
  background-color: #ffb40681;
  width: 5rem;
  height: 130%;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -1rem;
  margin-left: -0.6rem;
  z-index: -1;
}

.fpImg{
  width: 100%;
  height: 250px;
  object-fit: cover;
  position: relative;
}

.fpName{
  color: #333;
  font-weight: bold;
}

.fpCity{
  font-weight: 300;
}

.fpPrice{
  font-weight: 500;
}

.fpRating>button{
  background-color: #232F3E;
  color: #F69931;
  border: none;
  padding: 3px 8px;
  margin-right: 10px;
  font-weight: bold;
}

.fpRating>span{
  font-size: 14px;
}
.choiceDiv {
  position: absolute;
  margin: -0.5rem;
  
}
.fpItem .resortCoice {
  background-color: #232F3E;
  width: fit-content;
  color: #fff;
  position: relative;
}
.fpItem .resortCoice p{
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  
}
.fpItem .resortCoice p span {
  font-family: 'Montserrat', sans-serif;
  color: #F69931;

}
.fpItem .resortCoice::before {
  position: absolute;
  content: '';
  width: 0.8rem;
  height: 100%;
  top: 0;
  right: -6px;
  /* background-color: #ffb606; */
  background-color: #232F3E;
  transform: skewX(205deg);
}
