.featuredBackgroundColor{
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 6rem ;
}

.featuredBackgroundColor .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem ;
  padding-bottom: 1rem;
}

.featuredBackgroundColor .left .heading1 span{
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.featuredBackgroundColor .left .heading1 {
  margin-bottom: 5px;
}

.featuredBackgroundColor .left .heading1 span:nth-child(1){
  color: #ffb606;
}

.featuredBackgroundColor .left .heading1 span:nth-child(2){
  color: #111;
}

.featuredBackgroundColor .left .divider {
  background-color: #111;
  height: 1px;
  width: 4.5rem;
  margin-bottom: 1.6rem;
}

.featured{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  z-index: 1;
  overflow-x: hidden;
}

.featured .carousel {
  /* background-color: rgb(199, 124, 124); */
  cursor: grab;
}

.featured .carousel .inner-carousal {
  display: flex;
  gap: 30px;
  /* background-color: rgb(59, 59, 106); */
}

.featuredItem{
  position: relative;
  color: white;
  overflow: hidden;
  height: 16rem;
  flex:1;
  width: 22rem;
  pointer-events: none;
  box-shadow: 0 10px 15px rgb(79, 78, 78);
  border: 1px solid rgb(118, 118, 118);
}

.featuredImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featuredTitles{
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-transform: capitalize;
}
.featuredTitles .placeTitle span{
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  text-shadow: 1px 0 2px rgb(36, 36, 36);
  
}
.featuredTitles .properties span {
  font-family: 'Montserrat', sans-serif;

}